<template>
    <div class="pt-7 container-fluid">
        <b-card>
            <base-input type="text" :label="$t('general.search_area')" :placeholder="$t('notify.search_area')" v-model="filter.text" @input="searchTimeOut">
            </base-input>
        </b-card>
        <b-card no-body class="p-4">
            <el-table class="table-responsive table" header-row-class-name="thead-light" :data="notifies" v-loading='loading' style="width: 100%" :empty-text="$t('general.empty_table')">
                <el-table-column label="" prop="icon" min-width="80">
                    <template v-slot="{row}">
                        <b-avatar :variant="row.icon" :icon="$options.filters.showIcon(row.icon)"></b-avatar>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('notify.title')" prop="title" min-width="160">
                </el-table-column>

                <el-table-column :label="$t('notify.text')" prop="text" min-width="350">
                </el-table-column>

                <el-table-column :label="$t('general.created_date')" prop="createdAt" min-width="210" sortable>
                    <template v-slot="{row}">
                        {{row.createdAt | getDate}}
                    </template>
                </el-table-column>
            </el-table>
            <b-card-footer class="py-4 d-flex justify-content-end" v-if="pages>1&&!loading">
                <base-pagination v-model="pagination.page" :per-page="pagination.limit" :total="total" @change="changePage"></base-pagination>
            </b-card-footer>
        </b-card>
    </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { NOTIFY_LIST_REQUEST, NOTIFY_READALL } from '@/store/modules/define';
import { handleError } from '@/utils/func';
let self;

export default {
    computed: {
        ...mapGetters({ notifies: 'getNotifyList', total: 'getNotifyTotal', pages: 'getNotifyPages' })
    },
    data() {
        return {
            loading: true,
            pagination: {
                limit: 10,
                page: 1
            },
            filter: {
                text: ''
            },
            timer: null
        };
    },
    methods: {
        changePage(val) {
            self.loading = true;
            self.pagination.page = val;
            self.getData();
        },
        getData() {
            self.loading = true;
            self.$store
                .dispatch(NOTIFY_LIST_REQUEST, { pagination: self.pagination, filter: self.filter })
                .catch(err => {
                    handleError(self, err);
                })
                .finally(() => {
                    self.loading = false;
                });
        },
        searchTimeOut() {
            if (self.timer) {
                clearTimeout(self.timer);
                self.timer = null;
            }
            self.timer = setTimeout(() => {
                self.getData();
            }, 800);
        }
    },
    filters: {
        showIcon(value) {
            const icon = { info: 'info-circle-fill', warning: 'exclamation-circle-fill', danger: 'x-circle-fill', success: 'check-circle-fill' };
            return icon[value];
        }
    },
    created() {
        self = this;
        self.getData();
        self.$store.dispatch(NOTIFY_READALL);
    }
};
</script>